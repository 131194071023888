import React, { useEffect } from "react";
import TakePhoto from "./TakePhoto";
import Places from "./Places";
import Users from "./Users";
import Header from "./Header";
import Loading from './Loading';

import { useRecoilState } from 'recoil';
import { currentScreenState, isLoadingState, usersApi } from '../state';

const ScreenController = () => {
  const [currentScreen, setCurrentScreen] = useRecoilState(currentScreenState);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState);
  const [users, setUsers] = useRecoilState(usersApi);

  useEffect(() => {
    getUsersApi();
  }, []);

  const getUsersApi = () => {
    setIsLoading(true);

    const auth = {
      email: process.env.REACT_APP_AUTH_EMAIL,
      password: process.env.REACT_APP_AUTH_PASS,
    };

    const requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };

    fetch(`${process.env.REACT_APP_HOST_BASE}/api/customers?email=${auth.email}&password=${auth.password}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        setUsers(JSON.parse(result).data)
      })
      .catch(error => console.log('error', error))
      .finally(() => setIsLoading(false));
  }

  return (
    <>
      {currentScreen < 2 ?
        <>
          <Header />
          <main className="p-3 d-flex flex-column gap-2 justify-content-between">
            {
              currentScreen == 0 ?
                <Places />
                :
                <Users />
            }
          </main>
        </>
        :
        <TakePhoto place="Bósforo" />
      }

      {isLoading && <Loading />}
    </>
  );
}

export default ScreenController;