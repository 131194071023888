import React, { useRef } from "react";

export function useNFC(onScan = (event) => { }) {
  const nfc = useRef(null); // Remover o tipo de referência aqui
  const abortControllerRef = useRef(new AbortController());

  const startScan = () => {
    if (!("NDEFReader" in window)) {
      console.log("NDEFReader not supported.");
      return;
    }

    if (nfc.current) {
      console.log("Already scanning.");
      return;
    }

    const ndef = new window.NDEFReader();
    const onReadingEvent = (event) => {
      onScan(event);
    };

    nfc.current = ndef;
    return ndef
      .scan({ signal: abortControllerRef.current.signal })
      .then(() => {
        console.log("Scan started successfully.");
        nfc.current.addEventListener("reading", onReadingEvent);
      })
      .catch((error) => {
        console.log(`Error! Scan failed to start: ${error}.`);
      });
  };

  const stopScan = () => {
    if (!nfc.current) {
      return;
    }

    abortControllerRef.current.abort();
    nfc.current = null;
    abortControllerRef.current = new AbortController();
  };

  return {
    startScan,
    stopScan,
  };
}