import React, { useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { placeSelectedState, currentScreenState, usersSelected, isLoadingState } from '../state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import Header from './Header';
import Toast from 'bootstrap/js/dist/toast'

import img0 from '../assets/images/MOLDURA_PES_ISTAMBUL_2023_01-BOSFORO.png';
import img1 from '../assets/images/MOLDURA_PES_ISTAMBUL_2023_04-CIRAGAN_PALACE.png';
import img2 from '../assets/images/MOLDURA_PES_ISTAMBUL_2023_03-ESMA_SULTAN.png';
import img3 from '../assets/images/MOLDURA_PES_ISTAMBUL_2023_05-OLIGARK.png';
import img4 from '../assets/images/MOLDURA_PES_ISTAMBUL_2023_02-TOPKAPI.png';

const TakePhoto = () => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const toatsRef = useRef(null);
  const streamRef = useRef(null);
  const [photoDataUrl, setPhotoDataUrl] = useState(null);
  const [frameImage, setFrameImage] = useState(null);
  const [imgSent, setImgSent] = useState(false);

  const [placeSelected, setPlaceSelected] = useRecoilState(placeSelectedState);
  const [currentScreen, setCurrentScreen] = useRecoilState(currentScreenState);
  const [users, setUsers] = useRecoilState(usersSelected);
  const [isLoading, setIsLoading] = useRecoilState(isLoadingState);

  const modalFiles = [img0, img1, img2, img3, img4];
  const currentFile = modalFiles[placeSelected];

  const startCamera = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const rearCamera = devices.find(device => device.kind === 'videoinput' && device.label.includes('back'));

      if (rearCamera) {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: rearCamera.deviceId, width: 1920, height: 1080 } });
        streamRef.current = stream;
        videoRef.current.srcObject = stream;
      } else {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        streamRef.current = stream;
        videoRef.current.srcObject = stream;
      }

    } catch (error) {
      console.error('Error accessing camera:', error);
    }
  };

  useEffect(() => {
    loadFrameImage();
    startCamera();

    return () => {
      if (streamRef.current) {
        const tracks = streamRef.current.getTracks();
        tracks.forEach(track => track.stop());
      }
    };
  }, []);

  const loadFrameImage = async () => {
    try {
      const image = await loadImage(currentFile); // Replace with the path to your frame image
      setFrameImage(image);
    } catch (error) {
      console.error('Error loading frame image:', error);
    }
  };

  const loadImage = (src) => {
    return new Promise((resolve, reject) => {
      const image = new Image();
      image.onload = () => resolve(image);
      image.onerror = (error) => reject(error);
      image.src = src;
    });
  };

  const capturePhoto = async () => {
    const video = videoRef.current;
    const canvas = canvasRef.current;

    canvas.width = video.videoWidth;
    canvas.height = (video.videoWidth * 1080) / 1920;

    const topDrawVideo = (canvas.height - video.videoHeight) / 2;

    const context = canvas.getContext('2d');
    context.drawImage(video, 0, topDrawVideo, canvas.width, video.videoHeight);

    // Draw the frame image over the captured photo
    await context.drawImage(frameImage, 0, 0, canvas.width, canvas.height);

    const photoDataURL = canvas.toDataURL('image/jpeg'); // Convert canvas to base64 data URL
    setPhotoDataUrl(photoDataURL);

    const mediaStream = video.srcObject;
    mediaStream.getTracks().forEach(track => track.stop())
  };

  const discardPhoto = () => {
    setPhotoDataUrl(null);
    startCamera();
  };

  const sendPhotoToServer = () => {
    const blob = b64toBlob(photoDataUrl)
    handleUpload(blob);
  };

  const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
    const byteCharacters = atob(b64Data.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  const handleUpload = async (blob) => {
    setIsLoading(true);
    const url = process.env.REACT_APP_URL_API;

    const formData = new FormData();
    formData.append('file', blob);
    formData.append('users', JSON.stringify(users));

    const requestOptions = {
      method: 'POST',
      body: formData,
    };

    try {
      const response = await fetch(url, requestOptions);
      const responseData = await response.json();

      if (response.ok) {
        console.log('File uploaded successfully:', responseData);
        setUsers([]);
        setImgSent(true);
      } else {
        console.error('Error uploading file:', responseData);

        const toast = new Toast(toatsRef.current, { delay: 2500 })
        toast.show();
      }

      setIsLoading(false);
    } catch (error) {
      console.error('An error occurred:', error);

      const toast = new Toast(toatsRef.current, { delay: 2500 })
      toast.show();

      setIsLoading(false);
    }
  };

  return (
    <>
      {!imgSent ?
        <>
          {photoDataUrl && <Header />}
          <div className='vh-100 d-flex align-items-center justify-content-center'>
            {!photoDataUrl ? (
              <>
                <button className='btn-back rounded-circle mr-2 p-1 btn position-absolute end-0 top-0 bg-white shadow-sm' onClick={(e) => setCurrentScreen(1)}><FontAwesomeIcon icon={faArrowLeft} /></button>
                <button className='translate-middle-y rounded-circle btn btn-picture position-absolute end-0 top-50 bg-white shadow-sm' onClick={capturePhoto}><FontAwesomeIcon icon={faCamera} /></button>
              </>
            ) : (
              <div className='container mh-100'>
                <div className="row d-flex justify-content-center">
                  <img src={photoDataUrl} alt="Photo with Frame" className='w-75' />
                </div>
                <div className="mt-3 row d-flex flex-row justify-content-center gap-3">
                  <button className='btn btn-secondary btn-lg w-auto' onClick={discardPhoto}>TAKE ANOTHER PHOTO</button>
                  <button className='btn btn-secondary btn-lg w-auto' onClick={sendPhotoToServer}>SEND PHOTO</button>
                </div>
              </div>
            )}
            <video className='h-100 w-100' ref={videoRef} autoPlay style={(photoDataUrl) && { display: 'none' }}></video>
            <canvas ref={canvasRef} style={{ display: 'none' }}></canvas>
          </div>

          <div ref={toatsRef} className="toast align-items-center text-bg-danger border-0 position-absolute top-0 end-0" role="alert" aria-live="assertive" aria-atomic="true">
            <div className="d-flex">
              <div className="toast-body">
              An error occurred while trying to send the image. Please try again!
              </div>
              <button type="button" className="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
            </div>
          </div>
        </>
        :
        <>
          {photoDataUrl && <Header />}
          <div className='height-100 d-flex align-items-center justify-content-center flex-column gap-2'>
            <h1 className='text-white p-3 text-center'>PHOTO SENT SUCCESSFULLY</h1>
            <button className='btn btn-secondary btn-lg w-auto' onClick={(e) => setCurrentScreen(1)}>BACK</button>
          </div>
        </>
      }
    </>
  );
};

export default TakePhoto;