import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { useRecoilState } from 'recoil';
import { usersSelected, usersApi } from '../state';

const AddUser = ({ setModalAddUser }) => {

  const [users, setUsers] = useRecoilState(usersApi);
  const [usersSelect, setUsersSelect] = useRecoilState(usersSelected);

  const [inputFilter, setInputFilter] = useState("");
  const [filteredUsers, setFilteredUsers] = useState(users);

  useEffect(() => {
    const tempFilteredUsers = users.filter(item => (item.name.toLowerCase().includes(inputFilter.toLowerCase())))
    setFilteredUsers(tempFilteredUsers.filter(item => !usersSelect.some(objeto2 => objeto2.rfid === item.rfid)))
  }, [inputFilter, usersSelect]);

  const addUserSelect = (index) => {
    setUsersSelect(prevUsersSelected => [...prevUsersSelected, filteredUsers[index]]);
  }

  return (
    <div className="add-user position-absolute overflow-scroll p-3 bg-dark top-0 bottom-0 start-0 end-0 d-flex flex-column gap-3 flex-1">
      <button className="btn btn-primary btn-lg border-0 rounded-0 text-uppercase" type="button" onClick={e => setModalAddUser(false)}>Back</button>
      <h3 className="text-white fw-bold mb-0">SEARCH THE NAME BELOW:</h3>
      <input type="text" className="form-control mb-4" value={inputFilter} onChange={e => setInputFilter(e.target.value)} placeholder="Search by name" />

      <div className="list-content flex-1 gap-2 d-flex flex-column">
        {
          filteredUsers.map((user, index) => {
            return <div key={`user-add-${index}`} className="p-2 border text-white d-flex justify-content-between align-items-center">
              <p className="m-0">{user.name}</p>
              <button onClick={(e) => addUserSelect(index)} className="btn btn-success btn-sm rounded-0" type="button"><FontAwesomeIcon icon={faUserPlus} /></button>
            </div>
          })
        }
      </div>

    </div>
  );
}

export default AddUser;