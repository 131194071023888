import React from 'react';
import '../styles/header.css';

const Header = () => {
  return (
    <header className="header">
      <nav className="navbar shadow-sm">
        <div className="container-fluid">
          <div className="navbar-brand text-warning fw-bold fs-3">MEMORY MAKER</div>
        </div>
      </nav>
    </header>
  );
}

export default Header;