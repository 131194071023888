import React from "react";
import { useRecoilState } from 'recoil';
import { placesState, currentScreenState, placeSelectedState } from '../state';

const Places = () => {
  const [currentScreen, setCurrentScreen] = useRecoilState(currentScreenState);

  const [places, setPlaces] = useRecoilState(placesState);
  const [placeSelected, setPlaceSelected] = useRecoilState(placeSelectedState);

  const selectPlace = (indexPlace) => {
    setPlaceSelected(indexPlace);
    setCurrentScreen(1);
  }

  return ( 
  <div className="list-places d-flex flex-column gap-3">
    <h3 className="text-white fw-bold mb-4">SELECT THE LOCATION:</h3>

    {
      places.map((place, index) => {
        return <button key={`place-${index}`} onClick={(e) => selectPlace(place.id)} className="btn btn-secondary btn-lg text-start border-0 rounded-0 text-uppercase" type="button">{place.name}</button>
      })
    }
  </div> );
}
 
export default Places;