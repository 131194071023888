import React from "react";

const Loading = () => {
  return (<div className="loading bg-dark bg-opacity-50 top-0 start-0 end-0 bottom-0 position-absolute d-flex justify-content-center align-items-center">
    <div className="p-4 bg-white rounded-3 shadow-sm">
      <div className="spinner-border" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  </div >);
}

export default Loading;