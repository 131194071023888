import React from 'react';
import ReactDOM from 'react-dom/client';
import ScreenController from './components/ScreenController';

import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/main.css';
import { RecoilRoot } from 'recoil';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div className="mh-100 bg-dark">
      <RecoilRoot>
        <ScreenController />
      </RecoilRoot>
    </div>

  </React.StrictMode>
);