import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faUserPlus, faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import AddUser from "./AddUser";

import { useRecoilState, useRecoilCallback } from 'recoil';
import { placesState, currentScreenState, placeSelectedState, usersSelected, usersApi } from '../state';
import { useNFC } from "./useNFC";

const Users = () => {
  const [modalAddUser, setModalAddUser] = useState(false);

  const [currentScreen, setCurrentScreen] = useRecoilState(currentScreenState);
  const [places, setPlaces] = useRecoilState(placesState);
  const [placeSelected, setPlaceSelected] = useRecoilState(placeSelectedState);

  const [usersSelect, setUsersSelect] = useRecoilState(usersSelected);
  const [users, setUsers] = useRecoilState(usersApi);

  const [nfc, setNfc] = useState("NADA");
  const [nfcStarted, setNfcStarted] = useState(false);

  const {startScan, stopScan} = useNFC((event) => {
    handleNFCReading(event)
  });

  useEffect(() => {
    return (() => {
      stopScan();
    });
  }, []);

  function initNFC() {
    startScan();
    setNfcStarted(true);
    return;

    if ("NDEFReader" in window) {
      const ndef = new window.NDEFReader();
      try {
        ndef.scan();
        setNfc("NFC Start reading.")
        ndef.onreading = event => {
          handleNFCReading(event)
        }
      } catch (error) {
        setNfc('Error initializing NFC:' + JSON.stringify(error))
        console.error('Error initializing NFC:', error);
      }
    } else {
      setNfc("NFC not supported.")
      console.log('NFC not supported.');
    }

    setNfcStarted(true);
  }

  function handleNFCReading(event) {
    const serialNumber = event.serialNumber;
    setNfc("NFC data read: " + JSON.stringify(event));
    console.log('NFC data read:', serialNumber);

    const resultRfid = serialNumber.split(":").reduce((a, v, i, arr) => a + (i >= 4 ? 0 : parseInt(v, 16) * 256 ** i), 0).toString()

    //adiciona usuario pelo records rfid lido se ele ainda nao foi adicionado
    addUserSelected(resultRfid);
  }

  const addUserSelected = (rfid) => {
    const userFinded = users.find(objeto => objeto.rfid === rfid);
    if (userFinded) {
      addUniqueUser(userFinded);
    }
  }

  const addUniqueUser = useRecoilCallback(({ snapshot, set }) => async (user) => {
    const usersPromise = await snapshot.getPromise(usersSelected);
    
    if (!usersPromise.some(u => u.rfid === user.rfid)) {
      set(usersSelected, [...usersPromise, user]);
    }
  });

  const backScreen = () => {
    setPlaceSelected(0);
    setCurrentScreen(0);
  }

  const removeUser = (index) => {
    let users = [...usersSelect];
    users.splice(index, 1);
    setUsersSelect(users);
  }

  return (<>
    <div className="list-users d-flex flex-column gap-3 flex-1">
      <h3 className="text-white fw-bold mb-4">APPROACH THE BRACELET TO ADD TO THE GROUP:</h3>
      {/* <div className="text-white">NFC Content: {nfc}</div> */}
      <div className="list-content overflow-scroll flex-1 gap-2 d-flex flex-column">
        {
          usersSelect.map((user, index) => {
            return <div key={`user-${index}`} className="p-2 border text-white d-flex justify-content-between align-items-center">
              <p className="m-0">{user.name}</p>
              <button onClick={(e) => removeUser(index)} className="btn btn-danger btn-sm rounded-0" type="button"><FontAwesomeIcon icon={faTrash} /></button>
            </div>
          })
        }
      </div>

      {!nfcStarted &&
        <button onClick={(e) => initNFC()} className="btn btn-secondary border-0 rounded-0 text-uppercase text-white" type="button">START NFC</button>
      }
    </div>

    <div className="d-flex flex-column gap-3 mt-3 position-fixed bottom-0 start-0 end-0 bg-dark">
      <button className="btn btn-add-user p-3 border-0 btn-lg rounded-circle position-absolute end-0 bg-warning" type="button" onClick={() => setModalAddUser(true)}><FontAwesomeIcon icon={faUserPlus} /></button>

      {
        usersSelect.length > 0 &&
        <button onClick={(e) => setCurrentScreen(2)} className="btn btn-primary btn-lg border-0 rounded-0 text-uppercase" type="button">Take a photo</button>
      }
      <button onClick={(e) => backScreen()} className="btn border-0 rounded-0 text-uppercase text-white" type="button"><FontAwesomeIcon icon={faArrowsRotate} /> {places[placeSelected].name}</button>
    </div>

    {
      modalAddUser &&
      <AddUser setModalAddUser={setModalAddUser} />
    }

  </>);
}

export default Users;