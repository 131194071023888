import { atom } from 'recoil';

export const isLoadingState = atom({
  key: 'isLoadingState',
  default: false
});

export const currentScreenState = atom({
  key: 'currentScreenState',
  default: 0
});

export const placesState = atom({
  key: 'placesState',
  default: [
    {id: 0, name: 'Bósforo'},
    {id: 1, name: 'Çiragam Palace'},
    {id: 2, name: 'Esma Sultan'},
    {id: 3, name: 'Happy Hour Oligark'},
    {id: 4, name: 'Palácio TopKapi'},
  ]
});

export const placeSelectedState = atom({
  key: 'placeSelectedState',
  default: 0
});

export const usersSelected = atom({
  key: 'usersSelected',
  default: []
});

export const usersApi = atom({
  key: 'usersApi',
  default: []
});